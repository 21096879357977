import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/privacy-policy.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          {/* <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download priva
          </Button> */}
        </Row>

        <Row className="resume">
          {/* <Document file={pdf} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
          </Document> */}
          <div id="main-content">
            <p>PRIVACY AND DATA POLICY &nbsp;</p>
            <p>ISSUED BY REDDBANNER &nbsp;</p>
            <p>Last Updated: 04 SEP 2022 &nbsp;</p>
            <p>1. INTRODUCTION AND GENERAL TERMS &nbsp;</p>
            <p>
              These terms apply to the use of games and other products developed
              by REDDBANNER (RBG/ &lsquo;we&rsquo; / &lsquo;us&rsquo;). Our
              registered office is at Istanbul, Turkey. &nbsp;&nbsp;
            </p>
            <p>
              This privacy and data policy (&ldquo;Privacy Policy&rdquo;)
              applies and has effect in respect of all games, related online
              services (including online network play connectivity and
              interactivity) and other software and products made available by
              us (together the &ldquo;Game(s)&ldquo;), as well as any other
              online features relating to the Games including our website (the
              &ldquo;Website&rdquo;). Together the Game(s), and Website are
              referred to as the &ldquo;Online Services&rdquo;. &nbsp;
            </p>
            <p>
              If you have any questions or comments about this Privacy Policy,
              please contact us at reddbannergames@gmail.com &nbsp;
            </p>
            <p>
              We are committed to protecting and respecting your privacy. The
              Privacy Policy explains the basis on which personal information we
              collect from you will be processed by us or on our behalf. Where
              we decide the purpose or means for which personal data you supply
              through these Online Services is processed, we are the &ldquo;data
              controller.&rdquo; We will comply with all applicable data
              protection laws, including the General Data Protection Regulation
              2016/679. &nbsp;
            </p>
            <p>
              Please read this Privacy Policy carefully as it contains important
              information about the following: &nbsp;
            </p>
            <ul>
              <li>What information we may collect about you; &nbsp;</li>
              <li>How we will use information we collect about you; &nbsp;</li>
              <li>
                Whether we will disclose your details to anyone else; and &nbsp;
              </li>
              <li>
                Your choices and rights regarding the personal information you
                have provided to us. &nbsp;
              </li>
            </ul>
            <p>
              This Privacy Policy forms a part of and should be read in
              conjunction with our terms of use. &nbsp;
            </p>
            <p>
              The Online Services may contain hyperlinks to services owned and
              operated by third parties. These third party services may have
              their own privacy policies and we recommend that you review them.
              They will govern the use of personal information that you submit
              or which is collected by cookies and other tracking technologies
              whilst using these services. We do not accept any responsibility
              or liability for the privacy practices of such third party
              services and your use of these is at your own risk. &nbsp;
            </p>
            <p>
              We may make changes to this Privacy Policy in future, which will
              be posted on this page. You should check this page from time to
              time to ensure you are aware of any changes. Where appropriate we
              may notify you of changes through the Games. &nbsp;
            </p>
            <p>2. INFORMATION WE MAY COLLECT ABOUT YOU &nbsp;</p>
            <p>
              We collect and process the following information which may include
              your personal data. a) Information provided by you when using the
              Games &nbsp;
            </p>
            <p>(&ldquo;Basic Information&rdquo;) &nbsp;</p>
            <p>
              We may collect the following information from you when you play
              our Games: &nbsp;
            </p>
            <ul>
              <li>
                your mobile device&rsquo;s unique device ID (Device ID); &nbsp;
              </li>
              <li>your user preferences; and &nbsp;</li>
              <li>
                Core information related to your gameplay, such as your overall
                progression to a particular level or stage, your virtual
                currency stores or the completion of certain activities during
                your gameplay (for example, completing a tutorial, passing a
                level, making a purchase or viewing a video ad). &nbsp;
              </li>
            </ul>
            <p>
              b) Information used for the purposes of serving advertisements
              &nbsp;
            </p>
            <p>(&ldquo;Advertising Data&rdquo;) &nbsp;</p>
            <p>
              Through our third party advertising network partners, we may
              gather information about your devices when you install or play our
              Games, depending on the permissions you&rsquo;ve granted. This may
              include: &nbsp;
            </p>
            <ul>
              <li>
                the make, model and operating system of your device; &nbsp;
              </li>
              <li>
                properties of your device, such as screen size and orientation,
                audio volume and battery; &nbsp;&nbsp;
              </li>
              <li>the mobile network operator linked to your device; &nbsp;</li>
              <li>the Games played; &nbsp;</li>
              <li>
                the country, time zone and locale settings on your device (i.e.
                country and preferred language); &nbsp;
              </li>
              <li>network connection type and speed; &nbsp;</li>
              <li>IP Address; &nbsp;&nbsp;</li>
              <li>MAC address; &nbsp;&nbsp;</li>
              <li>internet browser used to access the Games; and &nbsp;</li>
              <li>
                the advertiser ID, which is an identifier unique to you if you
                use an Apple or Android device, but which doesn&rsquo;t reveal
                your name. &nbsp;
              </li>
            </ul>
            <p>
              c) Information collected for the purposes of providing analytics
              &nbsp;
            </p>
            <p>(&ldquo;Analytics&rdquo;) &nbsp;</p>
            <p>
              We may collect technical information about your use of the Online
              Services through the use of tracking technologies and analytics.
              &nbsp;
            </p>
            <p>Personal data we may collect includes the following: &nbsp;</p>
            <ul>
              <li>your Device ID; &nbsp;&nbsp;</li>
              <li>
                your user ID (which is generated by us and allocated to you when
                you first play our games); &nbsp;
              </li>
              <li>your device operating system &amp; version; &nbsp;</li>
              <li>your device make and model; &nbsp;</li>
              <li>game play attempts, progression and results; &nbsp;</li>
              <li>session game time start, end and duration; &nbsp;</li>
              <li>
                purchase transaction types and spend you have made using virtual
                items in the Games; &nbsp;
              </li>
              <li>the country of your Device; &nbsp;</li>
              <li>
                the time, date and install source of your first download (e.g.
                from a clicked advertisement); &bull; advertisements viewed and
                clicked; and &bull; identification of crashes and defects.
                &nbsp;
              </li>
            </ul>
            <p>d) Information about you collected from third parties &nbsp;</p>
            <p>(&ldquo;Third Party Log In Information&rdquo;) &nbsp;</p>
            <p>
              When you access the Games via a third party social media provider
              such as Facebook, we may collect and store personal and
              non-personal information which is available on that third party
              social media provider, such as your Facebook name, your profile
              picture or its URL, your Facebook ID and other public data of your
              friends, provided that you have expressly agreed to the use of
              Facebook within the Games. We will also receive technical data in
              order to ensure the Games connect to the correct Facebook account.
              &nbsp;
            </p>
            <p>3. WHY WE COLLECT INFORMATION ABOUT YOU &nbsp;</p>
            <div>
              <ol>
                <li>To provide our Games to you &nbsp;</li>
              </ol>
            </div>
            <p>
              We will use information about you (including Basic Information,
              Third Party Log In Information, Analytics and User Content) for
              delivering our Games to you under the terms of use agreed between
              us. The processing of information in this way is necessary for us
              to record your progress and current status within a Game, and to
              ensure the Games deliver the features promised and function
              properly, so that you have the best experience when playing any
              one of our Games. &nbsp;
            </p>
            <div>
              <ol>
                <li>
                  To help us improve the Online Services and fix any problems
                  &nbsp;
                </li>
              </ol>
            </div>
            <p>
              We may process information about you (including Basic Information,
              User Content and Analytics) so that we can analyse and improve our
              Games and Online Services. &nbsp;
            </p>
            <p>
              This processing is also necessary for us to pursue our legitimate
              interests of (i) ensuring that our Online &nbsp;
            </p>
            <p>
              Services function properly so that you and other users have the
              best experience when playing any of our Game(s) and using the
              other Online Services; (ii) improving the quality of our Online
              Services, and providing a better experience to our users; (iii)
              identifying and correcting any bugs in the Games and Online
              Services and iv) understanding the effectiveness of our marketing
              to attract new users and reengage our current users at an
              aggregated level. &nbsp;
            </p>
            <div>
              <ol>
                <li>
                  To acquire new users and engage current users &nbsp;&nbsp;
                </li>
              </ol>
            </div>
            <p>
              We may process information (including some Advertising Data and
              Analytics) to encourage engagement from our less active users and
              to notify them with new virtual items or other promotions on
              offer. We may also process this information to pursue our
              legitimate interests of acquiring new users for our Games. &nbsp;
            </p>
            <p>
              We use various forms of marketing to acquire new users. The
              information we collect about you may be used to improve the
              relevancy of our marketing, including the creation of
              &lsquo;lookalike&rsquo; audiences. This allows us to offer more
              relevant marketing to potential new service users without using
              information that personally identifies you. &nbsp;
            </p>
            <div>
              <ol>
                <li>
                  To improve the monetisation of the Games via in-app purchases
                  &nbsp;
                </li>
              </ol>
            </div>
            <p>
              We may process information (including Basic Information, User
              Content, Advertising Data and Analytics) to understand how users
              use our Games, and to compile statistical reports regarding that
              activity, as well as understanding how users progress, and their
              current status within a Game. This processing is necessary for us
              to pursue our legitimate interests of improving the Games and
              increasing the amount of revenue generated via in-app purchases.
              This may include the implementation of dynamic pricing models, so
              that in-app purchases are priced according to relevant factors,
              such as geography and gameplay progression so that we can offer
              temporary promotional discounts. &nbsp;
            </p>
            <div>
              <ol>
                <li>For advertising purposes &nbsp;</li>
              </ol>
            </div>
            <p>
              We may process Advertising Data to show you advertisements for
              third party services; including advertising that may reward you
              with benefits during your use of the Games. &nbsp;
            </p>
            <p>
              The information we collect about you may be used to understand how
              advertisements placed in our Games have performed or how many
              people have viewed certain advertisements. We aggregate this
              information so that it does not personally identify you or permit
              you to be treated individually. &nbsp;
            </p>
            <p>
              We may also use this information to reward users with benefits
              that can be used for further enjoyment of the Games from the
              viewing of certain advertising placed in our Games. &nbsp;
            </p>
            <p>
              We will not pass or process your information for the purposes of
              marketing by third parties unless you give your consent should we
              ask for it. After you have given your consent, you may withdraw it
              at any time. &nbsp;
            </p>
            <p>
              We may use the information we collect about you to serve you
              targeted advertisements in our Games. We do this to provide you
              with more relevant advertising content. We work with the following
              third party advertising networks : &nbsp;
            </p>
            <ul>
              <li>
                Chartboost &ndash;
                https://answers.chartboost.com/en-us/articles/115001489623
                &nbsp;
              </li>
              <li>
                Adcolony &ndash; https://www.adcolony.com/privacy-policy/
                &nbsp;&nbsp;
              </li>
              <li>
                Facebook &ndash; https://www.facebook.com/privacy/explanation
                &nbsp;&nbsp;
              </li>
              <li>
                Unity &ndash; https://unity3d.com/legal/privacy-policy &nbsp;
              </li>
              <li>
                Google &ndash; https://policies.google.com/technologies/ads
                &nbsp;&nbsp;
              </li>
            </ul>
            <p>
              If you would like to find out more about the way these third
              parties collect and process your information please refer to their
              respective privacy policies and/or terms and conditions by
              clicking on the links provided above. &nbsp;
            </p>
            <p>g) To prevent fraud and illegal activity &nbsp;</p>
            <p>
              We process personal data for our legitimate interests of ensuring
              that any use of the Online Services is lawful and non-fraudulent,
              does not disrupt the operation of our services, does not harass
              our staff or other individuals, to enforce our legal rights and to
              comply with our legal obligations. &nbsp;
            </p>
            <p>
              Where we reasonably believe that you are or may be in breach of
              any applicable laws or our terms of use, we may use your personal
              information to inform relevant third parties such as your law
              enforcement agencies about the content. &nbsp;
            </p>
            <p>
              We reserve the right to disclose your identity to any third party
              who is claiming that any User Content posted by you constitutes a
              violation of their intellectual property rights, or of their right
              to privacy. You may not copy, download, or embed any User Content
              without the express written permission of the content owner. You
              may not use any User Content for any commercial purposes, or any
              public performances. &nbsp;
            </p>
            <p>
              We work with Apple and Google to assist us with fraud prevention
              and the detection of any illegal activity (e.g. to verify in-app
              purchases made through iTunes and Google Play). &nbsp;
            </p>
            <p>4. DATA SHARING &nbsp;</p>
            <p>
              We will share your information with third parties only in the ways
              that are described in this Privacy Policy &nbsp;
            </p>
            <p>
              Group members, personnel, suppliers or subcontractors: We keep
              your information confidential, but may disclose it to any member
              of our group (which means our subsidiaries, our ultimate holding
              company and its subsidiaries, as defined in section 1159 of the
              Companies Act 2006), our personnel, suppliers or subcontractors
              insofar as it is reasonably necessary for the purposes set out in
              this Privacy Policy. However, this is on the basis that they do
              not make independent use of the information, and have agreed to
              safeguard it. &nbsp;&nbsp;
            </p>
            <p>
              Merger or acquisition: If we are involved in a merger,
              acquisition, or sale of all or a portion of its assets, any third
              party to which we sell or transfer our assets to will have the
              right to continue to use the personal and other information that
              you provide us in the manner set out in this Privacy Policy.
              &nbsp;
            </p>
            <p>
              Required by law: In addition, we may disclose your information to
              the extent that we are required to do so by law (which may include
              to government bodies and law enforcement agencies); in connection
              with any legal proceedings or prospective legal proceedings; and
              in order to establish, exercise or defend our legal rights
              (including providing information to others for the purposes of
              fraud prevention). &nbsp;
            </p>
            <p>
              Enforcement: We may also disclose your personal information to
              third parties in order to enforce or apply the terms of
              agreements, to investigate potential breaches, or to protect the
              rights, property or safety of SKG, our customers, or others.
              &nbsp;
            </p>
            <p>
              Digital Content Stores: Where the Games are downloaded through
              Google Play, iTunes and/or Amazon Appstore, we may disclose your
              personal information to Google, Apple and/or Amazon respectively
              for the purposes of facilitating any in-Game payments made through
              these platforms. &nbsp;
            </p>
            <p>5. AUTOMATED DECISION MAKING &nbsp;</p>
            <p>
              We may use automated decision making methods which do not involve
              human involvement to process your information only in the ways
              that are described in this Privacy Policy &nbsp;
            </p>
            <p>
              We may process your personal information via automated decision
              making methods in order to identify users who are suspected of
              cheating in the Games. Our automated decision making methods are
              designed to detect cheating by looking out for (i) excessive
              currency stores against actual completed gameplay; and (ii)
              excessive content against actual purchases made and completed
              gameplay. If users are determined to be or suspected of cheating
              the following actions may be taken and which will be unknown to
              the user: &nbsp;
            </p>
            <ul>
              <li>
                an increase in the number of advertisements displayed within the
                Games; and &nbsp;
              </li>
              <li>
                an increase in the frequency of suspected cheats playing against
                other suspected cheats. We may block users from the Games and/or
                the Online Services who are determined to be cheating following
                an automated decision process. In this scenario the user will be
                notified of the outcome and will have the right to obtain human
                intervention to express his or her point of view and to contest
                the decision to block access to the Games and/or the Online
                Services. &nbsp;
              </li>
            </ul>
            <ol>
              <li>CONSUMER CONTROL AND OPT-OUT OPTIONS &nbsp;</li>
            </ol>
            <p>
              If you do not wish to be part of a lookalike campaign, receive
              notifications from us or be served with targeted advertisements
              you can opt-out by changing your advertising and notification
              settings on your device directly. &nbsp;&nbsp;
            </p>
            <p>
              You can withdraw your consent to targeted advertising by changing
              your advertising settings on your device at any time. &nbsp;
            </p>
            <div>
              <ol>
                <li>
                  YOUR RIGHTS IN RELATION TO PERSONAL DATA WHICH WE PROCESS
                  RELATING TO YOU &nbsp;You have the following rights over the
                  way we process personal data relating to you, as set out in
                  the table below. We aim to comply without undue delay, and
                  within one month at the latest. To make a request, please let
                  our Data Protection Officer know by sending an email to
                  trafficracergame@gmail.com. &nbsp;
                </li>
              </ol>
            </div>
            <p>
              a) Ask for a copy of data we are processing about you and have
              inaccuracies corrected &nbsp;
            </p>
            <p>
              You have the right to request a copy of the personal information
              we hold about you and to have any inaccuracies corrected. &nbsp;
            </p>
            <p>
              We will use reasonable efforts to the extent required by law to
              supply, correct or delete personal information held about you on
              our files (and with any third parties to whom it has been
              disclosed to). b) Object to us processing data about you &nbsp;
            </p>
            <p>
              You can ask us to restrict, stop processing, or to delete your
              personal data if: &nbsp;
            </p>
            <ul>
              <li>
                you consented to our processing the personal data, and have
                withdrawn that consent; &nbsp;
              </li>
              <li>
                we no longer need to process that personal data for the reason
                it was collected; &nbsp;
              </li>
              <li>
                we are processing that personal data because it is in the public
                interest or it is in order to pursue a legitimate interest of
                RBGor a third party, you don&rsquo;t agree with that processing,
                and there is no overriding legitimate interest for us to
                continue processing it; &nbsp;
              </li>
              <li>the personal data was unlawfully processed; &nbsp;</li>
              <li>
                you need the personal data to be deleted in order to comply with
                legal obligations; &bull; the personal data is processed in
                relation to the offer of a service to a child. &nbsp;&nbsp;
              </li>
            </ul>
            <p>
              c) Obtain a machine readable copy of your personal data, which you
              can use with another service&nbsp;
            </p>
            <p>provider &nbsp;</p>
            <ul>
              <li>
                If we are processing data in order to perform our obligations to
                you, or because you consented, or if that processing is carried
                out by automated means, we will help you to move, copy or
                transfer your personal data to other IT systems. &nbsp;
              </li>
              <li>
                If you request, we will supply you with the relevant personal
                data in digital format. Where it is technically feasible, you
                can ask us to send this information directly to another IT
                system provider if you prefer. d) Make a complaint to a
                Supervisory Authority &nbsp;
              </li>
              <li>
                If you are unhappy with the way we are processing your personal
                data, please let us know by contacting us via the email. &nbsp;
              </li>
              <li>
                If you do not agree with the way we have processed your data or
                responded to your concerns, an alternative is to submit a
                complaint to a Data Protection Supervisory Authority. &nbsp;
              </li>
            </ul>
            <ol>
              <li>DATA RETENTION &nbsp;</li>
            </ol>
            <p>
              We will hold your personal information on our systems for as long
              as is necessary for the relevant service, or as otherwise
              described in this Privacy Policy. &nbsp;
            </p>
            <div>
              <ol>
                <li>CHILDREN &nbsp;</li>
              </ol>
            </div>
            <p>
              We do not use our Online Services to knowingly solicit information
              from or market to children under the age of 13. Our terms of use
              prohibit users aged under 13 years from accessing our Online
              Services. In the event that we learn that we have collected
              personal information from a child under 13 years of age we will
              delete that information as quickly as possible. If you believe
              that we might have any information from or about a child under 13
              years of age please contact us at reddbannergames@gmail.com.
              &nbsp;10. SECURITY &nbsp;
            </p>
            <p>
              We will take all reasonable technical and organisational
              precautions to prevent the loss misuse or alteration of your
              personal information. For example, our databases are password
              protected and limited to essential employees only (such as
              RBGmanagement or employees whose main role requires system
              access). &nbsp;
            </p>
            <p>
              Please be aware that, although we endeavour to provide reasonable
              security for information we process and maintain, no security
              system can prevent all potential security breaches. &nbsp;
            </p>
            <p>CONTACT INFORMATION &nbsp;</p>
            <p>
              All questions, comments or enquiries should be directed to RBGat
              reddbannergames@gmail.com. We will endeavour to respond to any
              query or questions within three business days. &nbsp;
            </p>
            <p>
              &copy; 2022 REDBANNER. All trade marks are the property of the
              relevant owners. All rights reserved. &nbsp;
            </p>
          </div>
        </Row>

        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row> */}
      </Container>
    </div>
  );
}

export default ResumeNew;
